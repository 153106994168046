@import "react-alice-carousel/lib/alice-carousel.css";



.canvas-img{
    height: 100vh;
    width: 100vw;
    position: absolute;
}

.display {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: auto;
    justify-content: space-around;
  
}

.create-templates {
 display: flex;
 flex-direction: column;
 width: 100vw;
 overflow-x: hidden;
}



.carousel-images {
    width: 10vw;
    height: 20vh;
}

.carousel{
    margin-top:  20vh;
}

.selected{
    border: solid rgb(47, 131, 249) 3px;
}

