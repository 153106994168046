.generate-templates {

}

.custom-upload-container {
    height: 55%;
    width: 55%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .generate-button-container {
    margin-top: 16px; /* Adjust the margin as needed */
    width: 100%; /* Make the button full width */
    background-color: #6a5acd;
    color: white;
    border: none;
  }

  .loading-wrapper{
    height: 55%;
    width: 55%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .content {
    padding: 50px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  /* Adjust the size of the checkbox */
/* .ant-checkbox-inner {
    width: 400px;
    height: 400px;
  }
   */
  /* Adjust the size of the checkmark inside the checkbox */
  /* .ant-checkbox-checked .ant-checkbox-inner::after {
    width: 60px;
    height: 60px;
    
  } */
  
  .ant-checkbox-inner, .ant-checkbox-input {
    transform: scale(2)
  }

  .download-button{
    position: fixed;
    right: 10vh;
    top: 10vh;
    width: 10vh;
    

  }